






















































































































































































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { isFuture, isPast, isToday } from "date-fns";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import { queries } from "@/lib/query/queries";
import { useAppStore } from "@/pinia/app";
import { getDateFromDatum, getDateFromDatumAndTijd, getDayMonth, getWeekDay } from "@/utils/date";
import { getCoachImage } from "@/utils/images";

const LIMITS = [25, 50, 100] as const;

export default defineComponent({
	components: { Label },
	setup() {
		const appStore = useAppStore();
		const { gym: currentGym } = useCurrentGym();

		const { data: gyms } = useQuery(queries.gym.all());
		const { data: grouplessons } = useQuery(queries.grouplesson.all());

		const limit = ref<(typeof LIMITS)[number]>(25);
		const pageIndex = ref<number>(0);
		const filter_time = ref<"today" | "future" | "past" | undefined>("today");
		const filter_grouplesson = ref<number | undefined>(undefined);
		const filter_gym = ref<number | undefined>(currentGym.value?.id);
		const filter_room = ref<number | undefined>(undefined);
		const filter_coach = ref<number | undefined>(undefined);

		const { data: coaches } = useQuery({
			...queries.coach.all({ gymId: filter_gym.value }),
			queryKey: computed(() => queries.coach.all({ gymId: filter_gym.value }).queryKey),
		});

		const filterValue_gym = computed(
			() => gyms.value?.find((gym) => gym.id === filter_gym.value)?.naam,
		);
		const filterValue_room = computed(
			() =>
				gyms.value?.flatMap((gym) => gym.zalen).find((room) => room.id === filter_room.value)?.naam,
		);

		watch(currentGym, (newValue) => (filter_gym.value = newValue?.id), { immediate: true });
		watch(filter_time, () => (pageIndex.value = 0));
		watch(filter_grouplesson, () => (pageIndex.value = 0));
		watch(filter_room, () => (pageIndex.value = 0));
		watch(filter_coach, () => (pageIndex.value = 0));
		watch(filter_gym, (newValue, oldValue) => {
			pageIndex.value = 0;

			if (newValue !== oldValue) {
				filter_room.value = undefined;
				filter_coach.value = undefined;
			}
		});

		const { isLoading, data } = useQuery(queries.lesson.current());

		const lessons = computed(() =>
			(data.value ?? []).filter((lesson) => {
				const time =
					filter_time.value === undefined ||
					(filter_time.value === "today" &&
						isToday(getDateFromDatumAndTijd(lesson.datum, lesson.tijd))) ||
					(filter_time.value === "future" &&
						isFuture(getDateFromDatumAndTijd(lesson.datum, lesson.tijd))) ||
					(filter_time.value === "past" &&
						isPast(getDateFromDatumAndTijd(lesson.datum, lesson.tijd)));

				const grouplesson =
					filter_grouplesson.value === undefined ||
					lesson.groepsles_id === filter_grouplesson.value;

				const gym =
					filter_gym.value === undefined || lesson.vestiging_naam === filterValue_gym.value;

				const room =
					filter_room.value === undefined ||
					(lesson.vestiging_naam ===
						gyms.value?.find((gym) => gym.zalen.find((room) => room.id === filter_room.value))
							?.naam &&
						lesson.zaal_naam === filterValue_room.value);

				const coach = filter_coach.value === undefined || lesson.trainer_id === filter_coach.value;

				return time && grouplesson && gym && room && coach;
			}),
		);

		const paginatedLessons = computed(() =>
			lessons.value.slice(pageIndex.value * limit.value, (pageIndex.value + 1) * limit.value),
		);

		const pages = computed(() => {
			if (!lessons.value || !lessons.value.length || !limit.value) {
				return 0;
			}

			return Math.ceil(lessons.value.length / limit.value);
		});

		function roomsByGym(id: number) {
			return gyms.value?.find((gym) => gym.id === id)?.zalen ?? [];
		}

		function coachesByGym(id: number) {
			return coaches.value?.data.filter((coach) => coach.gymIds.includes(id));
		}

		function resetFilters() {
			filter_time.value = "today";
			filter_grouplesson.value = undefined;
			filter_gym.value = currentGym.value?.id;
			filter_room.value = undefined;
			filter_coach.value = undefined;
		}

		return {
			getDateFromDatum,
			getWeekDay,
			getDayMonth,
			getCoachName,
			getCoachImage,
			appStore,
			LIMITS,
			gyms,
			coaches,
			grouplessons,
			currentGym,
			isLoading,
			filter_time,
			filter_grouplesson,
			filter_gym,
			filter_room,
			filter_coach,
			limit,
			pageIndex,
			data,
			lessons,
			paginatedLessons,
			pages,
			roomsByGym,
			coachesByGym,
			resetFilters,
		};
	},
});
